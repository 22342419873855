<script>
import ElementsBase from './ElementsBase'

export default {
	extends: ElementsBase,
	name: 'StackExercises',
	props: ['stackKey'],
	data: () => ({
		showCreateStackButton: false
	}),
	computed: {
		stack() {
			return this.$store.state.elements.root[this.stackKey]
		},
		list: {
			get() {
				return this.stack.exercises
			},
			set(list) {
				this.$store.dispatch('elements/setStackExercisesList', {
					key: this.stack.key,
					value: list
				})
			}
		},
		showCreate() {
			if (this.stack.type === 'linkedStack') return false
			else if (
				(this.stack.type === 'teamStack' && this.$store.state.profile.teams[this.$store.state.exerciseBrowser.folderData[this.stack.folderId].teamId].teamExercises !== 'readWrite')
			) return false
			else if (
				(this.stack.type === 'orgStack' && this.$store.state.profile.user.orgExercises !== 'readWrite')
			) return false				
			else return true
		}
	},
	methods: {
		onSortEnd({ oldIndex, newIndex }) {
			if (oldIndex !== newIndex) this.$nextTick().then(() => this.$store.dispatch('elements/moveStackExercise', { index:newIndex, stackKey: this.stack.key }))
		},
		async onCopy() {
			if (this.$store.state.bus.contentType === 'browserElements') {
				const ids = this.$store.state.bus.bus
				if (
					!this.$store.getters['elements/complete'](ids) &&
					!await this.$store.dispatch('exerciseBrowser/fetchByIdsForCopy', ids.join(','))
				) return
				const { exercises } = this.$store.getters['elements/getElementsForCopy'](ids, true)
				this.$store.dispatch('exerciseBrowser/copyToStack', { key: this.stack.key, exercises })
			} else if (this.$store.state.bus.contentType === 'setElements') {
				const { exercises } = this.$store.getters['sets/getElementsForCopy'](this.$store.state.bus.bus)
				exercises.forEach(x=>{
					delete x.customId
					delete x.stockId
					delete x.orig
				})				
				this.$store.dispatch('exerciseBrowser/copyToStack', { key: this.stack.key, exercises })
			}
		},
		onCreate() {
			this.$store.dispatch('exerciseBrowser/createStackExercise', this.stack.key)
				.then(([exerciseKey]) => {
					this.$store.dispatch('exerciseBrowser/toggleDetail', exerciseKey)
						.then(() => this.$store.dispatch('exerciseBrowser/setSelectedSub', [exerciseKey]))
				}) 					
		}
	}
}
</script>

<style lang='scss'>
/*
.exerciseBrowserElements .exerciseBrowserElements {
	grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
	padding: 0
}
*/
</style>